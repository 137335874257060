import React from 'react';
import FormHandler from '../../components/FormHandler/FormHandler';
import { Formik, Form, Field } from 'formik';
import Layout from '../../components/Layout/Layout';
import FieldInfo from '../../components/Formular/FieldInfo/FieldInfo';
import Button from '../../components/UI/Button/Button';
import { Redirect } from "@reach/router";
import TextareaAutosize from 'react-textarea-autosize';
import Seo from '../../components/SEO/SEO';


export default (props) => {

  if (!props.location.state || !props.location.state.manufacName) {
    return <Redirect noThrow to={`/hersteller/`} />;
  }
  // use own responseHandler instead of the default Handler in FormHandler.js
  const isAnswer = !props.location || Boolean(props.location.state.question);

  function myResponseHandler(responseData, thisFM, error = false) {
    //wordpress returned some error
    if (error) {
      let errorMsg = responseData.response.data.message;
      errorMsg = errorMsg.indexOf("Parameter: author_email") > -1 ? "Bitte gebe eine gültige E-Mail-Adresse ein." : errorMsg;

      thisFM.setState({
        error: true,
        sendingData: false,
        responseMessage: errorMsg,
        dataSendSuccess: false
      });
    } else {
      thisFM.setState({
        error: false,
        sendingData: false,
        responseMessage: `Danke! Deine ${isAnswer ? 'Antwort' : 'Frage '} wurde erfolgreich an uns gesendet. Du erhältst gleich eine E-Mail von uns.`,
        dataSendSuccess: true
      });
    }
  }

  return (
    <>
      <Layout
        headlines={["Fragen an den Hersteller"]}
        className="centeredPage--TextPage"
      >
        {!isAnswer ?
          <h1>Stelle eine Frage zu {props.location.state.manufacName} </h1>
          :
          <>
            <h1>Beantworte die Frage von {!props.location || props.location.state.question_author} </h1>
            <h2><i>{!props.location || props.location.state.question}</i></h2>
          </>
        }
        <FormHandler
          sendTo='https://tiny-house-helden.de/wp-herstellerfragen/wp-json/wp/v2/comments'
          requiredInputs={['author_email', 'author_name', 'content', 'dsgvo']}
          myResponseHandler={myResponseHandler}
        >
          {({ formikAttr, setClassDynamically, state, FHstyle }) => (
            <Formik
              {...formikAttr}
              initialValues={{ author_email: '', author_name: '', content: '', post: props.location.state.postId, parent: props.location.state.parentId }}
            >
              {({ isSubmitting }) => (
                <Form className={FHstyle.Form} >
                  <input type="hidden" name="post" value={props.location.state.postId} />
                  <input type="hidden" name="parent" value={props.location.state.parentId} />
                  <fieldset
                    disabled={state.sendingData}
                    // style={state.dataSendSuccess ? {display:'none'}:  null}
                    style={{
                      display: (state.dataSendSuccess ? "none" : "inherit"),
                      opacity: (state.sendingData ? "0.5" : "initial")
                    }}
                  >
                    <p></p>
                    <FieldInfo
                      label={{ for: 'content', text: isAnswer ? 'Deine Antwort*' : 'Stelle hier deine Frage*' }}
                      className={FHstyle.hasFloatLabel}
                    >
                      {/* <Field className={setClassDynamically('content')}  type="text" name="content" placeholder=" " /> */}
                      <Field
                        name="content"
                        render={({ field }) => (
                          <TextareaAutosize
                            {...field}
                            className={[setClassDynamically("content", this)].join(' ')}
                            placeholder=" "

                          />
                        )}
                      />
                    </FieldInfo>
                    <p><br /><br />Nachfolgend noch ein paar Angaben über dich:</p>

                    <FieldInfo
                      label={{ for: 'author_name', text: 'Dein Name*' }}
                      className={FHstyle.hasFloatLabel}
                    >
                      <Field className={setClassDynamically('author_name')} type="text" name="author_name" placeholder=" " />
                      {/* <ErrorMessage name="name" component="div" /> */}
                    </FieldInfo>

                    <FieldInfo
                      label={{ for: 'author_email', text: 'E-Mail*' }}
                      className={FHstyle.hasFloatLabel}
                    >
                      <Field
                        className={setClassDynamically('author_email')}
                        type="email"
                        name="author_email"
                        placeholder=" "
                      />
                    </FieldInfo>


                    <p> Deine hier getätigten Angaben und möglicherweise weitere Daten werden bei uns gesetzeskonform gespeichert. Weiteres erfährst du
                      in unserer Datenschutzerklärung (insb. Kapitel "Fragen und Antworten zu einem Hersteller").</p>
                    <label className={FHstyle.labelCheckbox} >
                      <Field className={setClassDynamically('dsgvo', 'checkbox')} type="checkbox" name="dsgvo" />
                      <span >
                        Ja, ich bin mit dem Inhalt der {" "}
                        <a target="_blank" href='/datenschutz'>Datenschutzerklärung</a> einverstanden.
                      </span>
                    </label>

                    <Button color="black">
                      <button type="submit" disabled={isSubmitting}>
                        {isAnswer ? 'Antwort' : 'Frage '} senden
                      </button>
                    </Button>
                  </fieldset>
                </Form>
              )}
            </Formik>)}
        </FormHandler>
      </Layout>
    </>
  );
}

export function Head() {
  return (
    <Seo
      noIndex
      title='Frage zu einem Tiny House Hersteller | TinyHouseHelden'
      meta_description=
      {'Stelle hier eine Frage zu einem Tiny House Hersteller!'}
    />

  )
}